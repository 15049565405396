<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1450px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
      >
        {{ $t("MENU.NEW") }} {{ $t("MENU.EVENT") }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-model="selectedLocale"
                :items="languages"
                :disabled="languages.length == 1"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.events"
                v-model="formModel.status"
                :items="statuses.events.event"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :rules="nameRules"
                class="mt-4"
                :label="$t('FORM_INPUT_NAMES.title')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
                @change="handleChangeName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <!--:rules="nameRules"-->
              <v-text-field
                v-if="
                  formModel.id && formModel.translations[selectedLocale.lang]
                "
                class="mt-4"
                v-model="formModel.translations[selectedLocale.lang].slug"
                :label="$t('FORMS.slug')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.slug']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.slug'] = ''
                "
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="formModel.event_id"
                :items="computedEventCollection"
                attach
                item-text="name"
                item-value="id"
                :label="$t('FORMS.parent')"
                clearable="true"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="formModel.address_ids"
                :items="addressCollection"
                attach
                chips
                deletable-chips="true"
                item-text="name"
                item-value="id"
                :label="$t('MENU.ADDRESSES')"
                multiple
                @change="handleChangeAddressIds"
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.address_ids)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <!-- <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="formModel.categories"
                :items="eventCategoryCollection"
                attach
                chips
                deletable-chips="true"
                item-text="name"
                item-value="id"
                :label="$t('MENU.EVENT_CATEGORIES')"
                multiple
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.categories)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col> -->

            <v-col cols="12" sm="4" md="4">
              <v-row>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'combo_box',
                      label: 'Kapcsolati form',
                      multiple: false,
                      itemText: 'name',
                      itemValue: 'id',
                      itemsURL: 'contactForms/admin/1/contactForm',
                    }"
                    customFieldName="contactForm"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .contactForm
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="date_picker_date_from"
                v-model="date_picker_date_from"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_date_from"
                    :label="$t('FORMS.date_from')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="requiredRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_date_from"
                  no-title
                  scrollable
                  locale="hu-HU"
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    style="width='100%'"
                    text
                    color="primary"
                    @click="
                      date_picker_date_from = false;
                      date_date_from = null;
                      time_date_from.HH = '';
                      time_date_from.mm = '';
                    "
                  >
                    Dátum törlése
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.date_picker_date_from.save(date_date_from)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <vue-timepicker
                style="background: #fff; margin-top: 20px"
                :disabled="!date_date_from"
                v-model="time_date_from"
                format="HH:mm"
                input-width="12em"
                first-day-of-week="1"
              ></vue-timepicker>
            </v-col>

            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="date_picker_date_to"
                v-model="date_picker_date_to"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_date_to"
                    :label="$t('FORMS.date_to')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_date_to"
                  no-title
                  scrollable
                  locale="hu-HU"
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    style="width='100%'"
                    text
                    color="primary"
                    @click="
                      date_picker_date_to = false;
                      date_date_to = null;
                      time_date_to.HH = '';
                      time_date_to.mm = '';
                    "
                  >
                    Dátum törlése
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.date_picker_date_to.save(date_date_to)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <vue-timepicker
                style="background: #fff; margin-top: 20px"
                :disabled="!date_date_to"
                v-model="time_date_to"
                format="HH:mm"
                input-width="12em"
              ></vue-timepicker>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="date_picker_visible_from"
                v-model="date_picker_visible_from"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_visible_from"
                    :label="$t('FORMS.visible_from')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_visible_from"
                  no-title
                  scrollable
                  locale="hu-HU"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    style="width='100%'"
                    text
                    color="primary"
                    @click="
                      date_picker_visible_from = false;
                      date_visible_from = null;
                      time_visible_from.HH = '';
                      time_visible_from.mm = '';
                    "
                  >
                    Dátum törlése
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.date_picker_visible_from.save(date_visible_from)
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <vue-timepicker
                style="background: #fff; margin-top: 20px"
                :disabled="!date_visible_from"
                v-model="time_visible_from"
                format="HH:mm"
                input-width="12em"
              ></vue-timepicker>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-menu
                ref="date_picker_visible_to"
                v-model="date_picker_visible_to"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_visible_to"
                    :label="$t('FORMS.visible_to')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_visible_to"
                  no-title
                  scrollable
                  locale="hu-HU"
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    style="width='100%'"
                    text
                    color="primary"
                    @click="
                      date_picker_visible_to = false;
                      date_visible_to = null;
                      time_visible_to.HH = '';
                      time_visible_to.mm = '';
                    "
                  >
                    Dátum törlése
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.date_picker_visible_to.save(date_visible_to)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <vue-timepicker
                style="background: #fff; margin-top: 20px"
                :disabled="!date_visible_to"
                v-model="time_visible_to"
                format="HH:mm"
                input-width="12em"
              ></vue-timepicker>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                dense
                v-model="formModel.main_page"
                :label="$t('FORM_INPUT_NAMES.on_main_page')"
                class="mt-6"
              >
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                dense
                v-model="formModel.visible_in_list"
                :label="$t('FORM_INPUT_NAMES.visible_in_list')"
                class="mt-6"
              >
              </v-checkbox>
            </v-col>
            <!-- <v-col cols="12" sm="2" md="2">
              <v-checkbox
                dense
                v-model="formModel.visible_in_calendar"
                :label="$t('FORM_INPUT_NAMES.visible_in_calendar')"
                class="mt-6"
              >
              </v-checkbox>
            </v-col> -->
            <!-- <v-col cols="12" sm="2" md="2">
              <v-checkbox
                dense
                v-model="formModel.accentuated"
                :label="$t('FORM_INPUT_NAMES.accentuated')"
                class="mt-6"
              >
              </v-checkbox>
            </v-col>-->
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" md="4" v-if="activeSiteId == 2">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: 'FORM_INPUT_NAMES.author' }"
                customFieldName="author"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .author
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" v-if="activeSiteId == 1">
              {{ $t("FORMS.intro") }}
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].intro"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.intro'] =
                    ''
                "
                :config="editorConfigIntro"
              ></ckeditor>
              <small
                v-if="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".intro"] }}
              </small>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              {{ $t("FORMS.text") }}
              <ckeditor
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].text"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.text']
                "
                @input="
                  messages['translations.' + selectedLocale.lang + '.text'] = ''
                "
                :config="editorConfig2"
                @blur="handleGenerateSEOText"
              ></ckeditor>
              <small
                v-if="messages['translations.' + selectedLocale.lang + '.text']"
                style="color: red"
              >
                {{ messages["translations." + selectedLocale.lang + ".text"] }}
              </small>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'button',
                  label: 'FORM_INPUT_NAMES.button_text',
                }"
                customFieldName="button_more"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .button_more
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-combobox
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].tags"
                :items="computedTagCollection"
                :label="$t('FORM_INPUT_NAMES.tags')"
                multiple
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="bg-gray-100"
                    @click:close="
                      deleteChipComboBox(
                        item,
                        formModel.translations[selectedLocale.lang].tags
                      )
                    "
                    >{{ item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col cols="12">
              <v-autocomplete
                :items="computedRelatedNewsCollection"
                v-model="formModel.related_news"
                :label="$t('FORM_INPUT_NAMES.related_news')"
                item-text="label"
                item-value="id"
                multiple
                @input="setLimitRelatedNews"
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="bg-gray-100"
                    @click:close="
                      deleteChipAutocomplete(item, formModel.related_news)
                    "
                    >{{ item.label }}</v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORMS.seo_title',
                  charLimit: 60,
                }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORMS.seo_description',
                  charLimit: 160,
                }"
                customFieldName="seoDescription"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col
              cols="12"
              sm="2"
              md="2"
              class="mt-2"
              v-if="canImportMedia('featured_image')"
            >
              <v-btn
                color="primary"
                outlined
                @click="handleImportMedia('featured_image')"
                >{{ $t("FORM_INPUT_NAMES.import_images") }}</v-btn
              >
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="featured_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .featured_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.featured_image',
                  resolution: '1300x650',
                  selectButtonTextNotTransalated: '',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="card_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .card_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.card_image',
                  initialColumnClass:
                    'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row> -->
          <!-- <v-row>
            <v-col
              cols="12"
              sm="2"
              md="2"
              class="mt-2"
              v-if="canImportMedia('gallery')"
            >
              <v-btn
                color="primary"
                outlined
                @click="handleImportMedia('gallery')"
                >{{ $t("FORM_INPUT_NAMES.import_images") }}</v-btn
              >
            </v-col>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="gallery"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .gallery || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: -1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.description',
                      type: 'text',
                    },
                  ],
                  selectButtonText: $t('FORM_INPUT_NAMES.gallery'),
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row> -->
          <v-divider></v-divider>

          <v-row>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.og_site_name',
                }"
                customFieldName="og_site_name"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_site_name || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.og_title',
                }"
                customFieldName="og_title"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_title || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="og_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.og_image',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <CustomFieldComponent
                v-if="formModel && formModel.custom_fields"
                :config="{
                  type: 'multi_select',
                  multiple: false,
                  label: 'FORM_INPUT_NAMES.og_type',

                  items: og_types,
                }"
                customFieldName="og_type"
                :value="formModel.custom_fields.og_type || 'article'"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORM_INPUT_NAMES.og_description',
                }"
                customFieldName="og_description"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_description || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->
        <PreviewURLComponent
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          slug="slug"
          :lang="selectedLocale.lang"
          type="event"
          :formModel="formModel"
          @handlePreview="handleSaveModalForm"
        ></PreviewURLComponent>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm(null)"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Event";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import SiteService from "@/core/services/site.service.js";
import PreviewURLComponent from "@/view/components/PreviewURLComponent";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.common.js";
import "vue2-timepicker/dist/VueTimepicker.css";

const TRANSLATED_ATRIBUTES = ["name", "slug", "text", "intro"];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "author",
  "seoTitle",
  "seoDescription",
  "featured_image",
  "card_image",
  "gallery",
  "button_more",
  "contactForm",

  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
];

export const INITIAL_CUSTOM_FIELDS = {
  og_type: "",
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  tags: [],
  //related_news: [],
  translations: {},
  categories: [],
  custom_fields: INITIAL_CUSTOM_FIELDS,

  main_page: 0,
  event_relation_by: null, //To do...
  date_from: null,
  date_to: null,
  visible_from: null,
  visible_to: null,

  visible_in_list: 0,
  visible_in_calendar: 0,
  event_id: null,
  accentuated: 0,

  address_ids: [],
});

export default {
  name: "EventForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "eventCategoryCollection",
  ],
  components: {
    CustomFieldComponent,
    PreviewURLComponent,
    SnackBarInfoComponent,
    VueTimepicker,
  },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      date_picker_date_from: false,
      date_date_from: null,
      time_date_from: { HH: "", mm: "" },

      date_picker_date_to: false,
      date_date_to: null,
      time_date_to: { HH: "", mm: "" },

      date_picker_visible_from: false,
      date_visible_from: null,
      time_visible_from: { HH: "", mm: "" },

      date_picker_visible_to: false,
      date_visible_to: null,
      time_visible_to: { HH: "", mm: "" },

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],

      editorConfigIntro: {
        htmlEncodeOutput: false,
        entities: false,
        removeButtons: "Underline,JustifyCenter",
        toolbar: [
          ["Source"],
          ["Styles", "Format", "Font", "FontSize"],
          ["Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
        ],
      },

      editorConfig: {
        htmlEncodeOutput: false,
        entities: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
      },
      editorConfig2: {
        htmlEncodeOutput: false,
        entities: false,
        extraAllowedContent: "iframe[*]",
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images&maxWidth=706",
      },
      tagCollection: [],
      og_types: ["website", "article"],
      activeSiteId: SiteService.getActiveSiteId(),

      snackbar: false,
      text: "",
      timeout: 3000,
    };
  },
  computed: {
    ...mapGetters([
      "eventCollection",
      "contactFormCollection",
      "addressCollection",
    ]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.POST").toLowerCase();
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    computedTagCollection() {
      let collection = [];
      this.tagCollection.forEach((item) => {
        if (item.locale == this.selectedLocale.lang) {
          collection.push(item.name);
        }
      });
      return collection;
    },
    /*computedRelatedNewsCollection() {
      let collection = [];
      this.newsCollection.forEach((item) => {
        if (item.id == this.formModel.id) {
          return;
        }
        if (item.translations[this.selectedLocale.lang]) {
          collection.push({
            id: item.id,
            label: item.translations[this.selectedLocale.lang].name,
          });
        }
      });
      return collection;
    },*/
    computedEventCollection() {
      let collection = [];
      this.eventCollection.forEach((item) => {
        if (item.id !== this.formModel.id) {
          collection.push(item);
        }
      });
      return collection;
    },
    computedContactFormCollection() {
      let collection = [];
      this.contactFormCollection.forEach((item) => {
        console.log(item);
        //collection.push({id: item.id, text: item.name});
        collection.push(item);
      });
      return collection;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.snackbar = false;
        this.warningText = "";
        this.timeout = 3000;
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields)
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();
              //Set date and timepickers
              this.date_date_from = this.setDatePicker(data.date_from);
              this.time_date_from = this.setTimePicker(data.date_from);
              this.date_date_to = this.setDatePicker(data.date_to);
              this.time_date_to = this.setTimePicker(data.date_to);
              this.date_visible_from = this.setDatePicker(data.visible_from);
              this.time_visible_from = this.setTimePicker(data.visible_from);
              this.date_visible_to = this.setDatePicker(data.visible_to);
              this.time_visible_to = this.setTimePicker(data.visible_to);
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();
          //Set date and timepickers
          this.date_date_from = this.setDatePicker(null);
          this.time_date_from = this.setTimePicker(null);
          this.date_date_to = this.setDatePicker(null);
          this.time_date_to = this.setTimePicker(null);
          this.date_visible_from = this.setDatePicker(null);
          this.time_visible_from = this.setTimePicker(null);
          this.date_visible_to = this.setDatePicker(null);
          this.time_visible_to = this.setTimePicker(null);

          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchContactForm", "fetchAddress"]),
    getFromDateAndTimePickers(date_picker_value, time_picker_value) {
      let str = null;
      if (date_picker_value) {
        str = date_picker_value;
      }
      if (time_picker_value.HH && time_picker_value.mm) {
        str += " " + time_picker_value.HH + ":" + time_picker_value.mm + ":00";
      }
      return str;
    },
    setDatePicker(datetime) {
      if (datetime) {
        return new Date(
          Date.parse(datetime) - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
      return null;
    },
    setTimePicker(datetime) {
      if (datetime) {
        let date_time_array = datetime.split("T");
        let time_array = date_time_array[1].split(":");
        return { HH: time_array[0], mm: time_array[1] };
      }
      return { HH: "", mm: "" };
    },
    handleSaveModalForm(previewURL = null) {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!model.categories) model.categories = [];

      model.date_from = this.getFromDateAndTimePickers(
        this.date_date_from,
        this.time_date_from
      );
      model.date_to = this.getFromDateAndTimePickers(
        this.date_date_to,
        this.time_date_to
      );
      model.visible_from = this.getFromDateAndTimePickers(
        this.date_visible_from,
        this.time_visible_from
      );
      model.visible_to = this.getFromDateAndTimePickers(
        this.date_visible_to,
        this.time_visible_to
      );

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              setTimeout(() => {}, 0);
              this.$emit("saveModalForm", model.id);
              if (previewURL && previewURL != "") {
                window.open(previewURL, "_blank");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              setTimeout(() => {}, 0);
              this.$emit("saveModalForm", data.id);
              if (previewURL && previewURL != "") {
                window.open(previewURL, "_blank");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    fetchTag() {
      this.loader = true;
      ApiService.get(
        "events/admin/" + SiteService.getActiveSiteId() + "/eventTag"
      )
        .then(({ data }) => {
          this.tagCollection = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
    deleteChipComboBox(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    deleteChipAutocomplete(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
    getTimeFormat(date) {
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");
      return hours + ":" + minutes;
    },

    handleChangeName() {
      let seoTitle = "";
      seoTitle += this.formModel.translations[this.selectedLocale.lang].name;
      this.handleChangeCustomFieldValue("seoTitle", seoTitle);
    },

    handleGenerateSEOText() {
      if (
        !this.formModel.translations[this.selectedLocale.lang].custom_fields
          .seoDescription ||
        this.formModel.translations[this.selectedLocale.lang].custom_fields
          .seoDescription.length == 0
      ) {
        this.handleChangeCustomFieldValue(
          "seoDescription",
          this.formModel.translations[this.selectedLocale.lang].text
            .replace(/(<([^>]+)>)/gi, "")
            .slice(0, 160)
        );
      }
    },

    handleImportMedia(type) {
      let formModel = this.formModel;
      this.formModel = null;

      let fromImportIndex = 0;

      if (this.languages[fromImportIndex].lang == this.selectedLocale.lang) {
        fromImportIndex = 1;
      }

      formModel.translations[this.selectedLocale.lang].custom_fields[type] = [
        ...formModel.translations[this.languages[fromImportIndex].lang]
          .custom_fields[type],
      ];

      this.formModel = formModel;
    },

    canImportMedia(type) {
      let canImport = false;

      let fromImportIndex = 0;

      if (this.languages[fromImportIndex].lang == this.selectedLocale.lang) {
        fromImportIndex = 1;
      }

      if (
        this.formModel.translations[this.languages[fromImportIndex].lang] &&
        this.formModel.translations[this.languages[fromImportIndex].lang]
          .custom_fields[type] &&
        this.formModel.translations[this.languages[fromImportIndex].lang]
          .custom_fields[type].length > 0
      ) {
        if (
          this.formModel &&
          this.formModel.translations &&
          this.formModel.translations[this.selectedLocale.lang] &&
          (!this.formModel.translations[this.selectedLocale.lang].custom_fields[
            type
          ] ||
            (this.formModel.translations[this.selectedLocale.lang]
              .custom_fields[type] &&
              this.formModel.translations[this.selectedLocale.lang]
                .custom_fields[type].length == 0))
        ) {
          canImport = true;
        }
      }

      return canImport;
    },

    /*setLimitRelatedNews(selectedIds) {
      if (selectedIds.length > 4) {
        this.formModel.related_news = this.formModel.related_news.slice(0, 4);
      }
    },*/

    handleChangeAddressIds() {
      if (this.formModel.address_ids.length > 1) {
        this.formModel.address_ids = [this.formModel.address_ids[0]];
      }
    },
  },

  mounted() {
    this.fetchTag();
    this.fetchContactForm();
    this.fetchAddress();
    this.setTranslatedAttributes();
  },
};
</script>
